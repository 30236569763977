/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --search-bar-max-width: 450px;
    --search-bar-width: 270px;
    --search-clear-background: var(--secondary-base-color);
    --search-field-background: var(--secondary-base-color);
    --search-field-border-color: var(--primary-divider-color);
    --search-field-visible-background: #fff;
    --search-input-desktop-border-color: #000;
}

.SearchField {

    &_isVisible {
        opacity: 1;
        pointer-events: all;
    }

    &_isActive {
        --search-bar-width: 27vw;
        --search-field-border-color: var(--primary-light-color);
    }

    &-Wrapper {
        
    }

    &-Input {
        width: 100%;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        padding: 0;
    }

    &-Placeholder {
        font-size: 1.6rem;
        height: min-content;
        left: calc(50% - 5px);
        padding-left: 20px;
        pointer-events: none;
        position: absolute;
        top: 5px;
        transform: translate3d(-50%, 0, 0);
        visibility: hidden;
        width: min-content;

        // @include search-icon;

        &::before {
            bottom: 0;
            left: 0;
            margin: auto;
            top: 0;
            transform: translateY(-2px);
        }

        &_isActive {
            left: 0;
            opacity: .7;
            padding-left: 0;
            transform: translate3d(10px, 0, 0);

            &::before {
                opacity: 0;
            }
        }

        &_isPlaceholderVisible {
            visibility: visible;
        }
    }

    &-SearchIcon {
        padding: 0 10px;
        display: block;
        @include lg {
            padding: 0 30px;
        }
        svg {
            width: 15px;
            height: 15px;
            @include lg {
                width: auto;
                height: auto;
            }
        }
    }

    &-Button {
        position: absolute;
        top: 13px;
        right: 0;
        &:hover {
            fill: $color-accent;
        }
    }

    &-CloseIcon {
        // @include close-button;

        &::before,
        &::after {
            height: 22px;
            left: 8px;
            top: 2px;
            width: 2px;
        }
    }

    &-SearchWrapper {
        z-index: 12;
        background: var(--search-field-visible-background);
        height: 100%;
        max-width: 480px;
        position: fixed;
        right: 0;
        top: 0;
        transform: translateX(100%);
        transition: transform 300ms ease-in;
        width: 100%;
        will-change: transform;
        padding: 30px $container-gutter 0;

        &_isVisible {
            transform: translateX(0);
        }
    }

    &-SearchInnerWrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &-Overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(25, 28, 30, .2);
        z-index: 11;
    }
}
