/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --slider-crumb-color: #fff;
    --slider-crumb-background: #000;
}

.Slider {
    --translateX: 0;
    --translateY: 0;
    --animation-speed: 0;
    --slider-height: 0;
    --slide-height: 0;
    --slide-width: 0;
    --height-transition-speed: 0;

    max-width: 100%;
    overflow: hidden;
    touch-action: pan-y;

    transition: height var(--height-transition-speed);

    &-Wrapper {
        display: flex;
        align-items: flex-start;
        height: 100%;
        will-change: transform;
        transition: transform var(--animation-speed);
        transform: translate3d(var(--translateX), var(--translateY), 0);

        > * {
            flex-grow: 1;
            flex-shrink: 0;
            width: 100%;
            height: 100%;
        }

        &_isVertical {
            flex-direction: column;
        }
    }

    &-Image {
        text-align: right;
    }

    &-Crumbs {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        @include md {
            display: none;
        }
    }

    &-Crumb {
        width: 15px;
        height: 15px;
        background: #F4F4F4;
        border-radius: 50%;
        margin: 0 5px;
        vertical-align: middle;
        &_isActive {
            background: #5A6870;
        }

        &_isActive {
            
        }
    }
}
