/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.BlogCategories {
    margin-top: 20px;
    @include md {
        margin-top: 40px;
    }

    &-Category {
        display: inline-block;
        background: #F4F5F6;
        padding: 5px 15px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 300;
        margin: 10px 10px 0 0;
        cursor: pointer;
        color: $color-text;
        &:hover,
        &_Active {
            color: #FFF;
            background: #191C1E;
            text-decoration: none;
        }
    }
}
