/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.PasswordChangePage {
    &-Heading {
        @include page-title;
        padding: 30px $container-gutter;
        border-bottom: 1px solid #707070;
        margin: 0 -1 * $container-gutter 40px;
        @include lg {
            border-bottom: 0;
            text-align: center;
            padding: 0;
            margin: 25px 0 75px;
        }
    }

    &-Wrapper {
        display: flex;
        justify-content: center;
    }

    .Form {
        @include lg() {
            max-width: 390px;
        }
    }

    .Loader {
        margin: 0;
    }

    .Button {
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .Form {
        margin-top: 30px;
    }
}
