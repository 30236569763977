/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Footer {
    background: #000;
    padding: 20px 0;
    text-transform: uppercase;
    margin-top: 60px;
    @include text-sm;
    @include lg {
        padding: 30px 0;
        margin-top: 90px;
    }

    &_isHomepage {
        background: transparent;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        margin-top: 0;
        padding-bottom: 5px;

        @include lg {
            padding-bottom: 30px;
        }
    }

    &-Container {
        @include container;
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @include md {
            align-items: center;
        }
    }

    &-Left {
        font-size: 12px;

        @include md {
            font-size: 14px;
        }

        ul {
            @include lg {
                display: flex;
                align-items: center;
            }
        }
        li {
            @include lg {
                margin-right: 35px;
            }
        }
        a {
            color: #FFF;
            display: block;
            padding: 5px 0;
            @include lg {
                padding: 0;
            }
        }
    }

    &-Right {
        text-align: right;
        font-size: 12px;
        padding-top: 5px;

        @include md {
            font-size: 14px;
        }

        @include lg {
            display: flex;
            align-items: center;
            padding-top: 0;
        }

        ul {
            margin-top: 10px;
            @include lg {
                margin-top: 0;
                display: flex;
                align-items: center;
            }
        }

        li {
            @include lg {
                margin-left: 35px;
            }

            a {
                display: block;
                padding: 5px 0;
                @include lg {
                    padding: 0;
                }
            }
        }
    }

    &-Social {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @include xl {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 0;
        }
        a {
            margin: 0 7px;
            svg {
                fill: #FFF;
                height: 17px;
                @include lg {
                    height: 22px;
                }
            }
            &:hover svg {
                fill: var(--link-color);
            }

            @include lg {
                margin: 0 15px;
            }
        }
    }

    &-Credits {
        color: #FFF;
        a {
            color: #FFF;
        }
    }

    &-Newsletter {
        // position: absolute;
        top: 0;
        right: $container-gutter;
        @include lg {
            position: static
        }
        a {
            color: #000;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
            .Footer_isHomepage & {
                color: #FFF;
            }
        }
    }
}