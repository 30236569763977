table {
    td,
    th {
        font-size: 14px;
        padding: 20px 30px;
        text-align: left;
    }
}

.Table-Wrapper {
    max-width: 100%;
    overflow-x: scroll;
}
