/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.SearchPage {
    &-Container {
        max-width: $container-width;
        padding: 0 $container-gutter;
        margin: 0 auto;

        .CategoryDetails {
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 25px;
        }

        .CategoryPage-Filter {
            width: 100%;
        }
    }

    &-Field {
        margin-bottom: 40px;
        position: relative;

        input {
            padding-right: 56px;
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            width: 56px;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &-Input {
        height: 56px;
    }

    &-Cols {
        display: flex;
        gap: 42px;

        // Filters

        .CategoryFilterOverlay {
            @include md {
                width: auto;
                margin: 0;
                padding: 0;
            }

            &-Wrapper {
                @include md {
                    display: flex;
                    flex-direction: column;
                }
            }

            &-ResetSection {
                @include md {
                    width: 260px;
                    order: -1;
                }
            }

            &-ResetButton {
                @include md {
                    margin-bottom: 30px;
                }
            }

            .ResetAttributes {
                @include md {
                    margin-bottom: 0;
                }
            }
        }
        
        .CategoryConfigurableAttributes {
            display: block;

            .ExpandableContent-Button {
                @include md {
                    min-width: 260px;
                }
            }

            .ExpandableContent-Content {
                @include md {
                    position: static;
                    width: 260px;
                    box-shadow: none;
                    border-top: none;
                }
            }

        }

        .ProductConfigurableAttributes-Expandable {
            @include md {
                margin: 0 0 20px;
            }
        }

        // Products

        .CategoryPage-ProductListWrapper {
            width: 100%;
            padding: 0;
        }
    }

    &-Products {
        flex: 1;

        &_showPlaceholder {
            .SearchPage-Placeholder {
                display: flex;
            }

            .CategoryPage-ProductListWrapper {
                display: none;
            }
        }
    }

    &-Placeholder {
        display: none;
        align-items: center;
        justify-content: center;
        background: #F9F9F9;
        height: 300px;
        margin-top: 20px;

        @include md {
            margin-top: 0;
        }

        div {
            text-align: center;
        }

        svg {
            display: inline-block;
            vertical-align: center;
        }

        span {
            display: block;
            font-size: 18px;
            font-weight: 700;
            margin-top: 20px;
        }
    }
}

body > iframe {
    display: none;
}