/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --search-item-divider-color: #eee;
    --search-item-title-color: #000;
}

.SearchItem {
    margin-bottom: 5px;
    &:last-child {
        margin-bottom: 0;
    }

    &-Wrapper {
        display: flex;
        align-items: flex-start;
        align-items: center;
        .Image {
            width: 48px;
            height: 48px;
            padding-bottom: 0;
            flex-shrink: 0;
            margin-right: 20px;
        }
    }

    &-Content {
        flex: 1;
        width: calc(100% - 68px);
    }

    &-CustomAttribute {

    }

    &-Title {
        font-size: $fs-sm;
        font-weight: 400;

        &_isLoaded {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    &-Link {
        color: inherit;
        display: block;
        &:hover {
            color: $color-accent;
            text-decoration: none;
        }
    }
}
