/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.PostsDetails {
    @include container;
    max-width: 1180px;

    &-Title {
        margin: 25px 0 30px;
        text-align: center;
        @include md {
            margin: 25px 0 75px;
        }
    }

    &-Content {
        line-height: $lh-loose;

        ul,
        ol {
            font-size: 14px;
            font-weight: 300;
            margin-bottom: 15px;
            @include md {
                margin-bottom: 30px;
            }
        }

        li {
            position: relative;
            padding-left: 25px;
            &:before {
                content: ' ';
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background: #191C1E;
                position: absolute;
                top: 10px;
                left: 0;

            }
        }

        p {
            font-size: 14px;
            font-weight: 300;
            margin-bottom: 15px;
            @include md {
                margin-bottom: 30px;
            }
        }

        h2 {
            &:first-of-type {
                margin-top: 0;
            }
        }

        a {
            color: $color-text;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }

    .Image {
        display: block;
        padding-bottom: 66.8%;
        margin-bottom: 40px;
    }

    .video-responsive {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
        margin-bottom: 40px;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
