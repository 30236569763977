/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Field {
    margin-top: 20px;

    @include mobile {
        margin-top: 1.4rem;
    }

    &-Label {
        font-weight: 700;
    }

    &-ErrorMessage {
        font-size: 12px;
        font-weight: 400;
        color: var(--primary-error-color);
        margin-top: 5px;
    }

    &-Note {
        margin-bottom: .24rem;
        font-size: 1.2rem;
        font-weight: 600;
        color: var(--field-note);
        opacity: 0;
        height: 0;
        transition: height .1s ease-in, opacity .1s linear;
        will-change: height, opacity;

        @include mobile {
            margin-bottom: .28rem;
            font-size: 1.4rem;
        }

        &::before {
            content: '* ';
        }
    }

    &_isValid {
        input,
        textarea {
            border: 1px solid var(--primary-success-color);
        }
    }

    &_hasError {
        input,
        textarea {
            border: 1px solid var(--primary-error-color);
        }
    }

    &_type {
        &_checkbox,
        &_radio {
            cursor: pointer;
            label {
                display: block;
                position: relative;
                padding: 2px 0 2px 40px;
                margin: 0;
                font-weight: 400;
            }

            input {
                display: none;
                &:checked + .input-control {
                    &:before {
                        background: #5A6870;
                        border-color: #5A6870;
                    }
                    &:after {
                        content: url('data:image/svg+xml;base64, PHN2ZyBpZD0iQ2FwYV8xIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiBoZWlnaHQ9IjE1IiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjE1IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiNGRkYiPjxnPjxwYXRoIGQ9Im00NTAuNTg1IDY4LjU1Mi0yNTIuMDY1IDI1Mi4wNjUtMTM3LjEwNS0xMzcuMTA0LTYxLjQxNSA2MS40MTUgMTk4LjUyIDE5OC41MiAzMTMuNDgtMzEzLjQ4eiIvPjwvZz48L3N2Zz4=');
                    }
                }
            }

            .input-control {
                &:before {
                    content: ' ';
                    display: block;
                    width: 24px;
                    height: 24px;
                    border: 1px solid #DCDCDC;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                &:after {
                    position: absolute;
                    top: 4px;
                    left: 4px;
                }
            }
        }
    }

    &-Labels {
        span {
            letter-spacing: 1.05px;
        }
    }
}
