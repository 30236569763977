:root {
    --link-color: var(--primary-base-color);
    --link-hover: var(--primary-dark-color);
}

a {
    color: var(--link-color);

    &:hover {
        text-decoration: underline;
    }

    &:focus {
        text-decoration: underline;
    }
}
