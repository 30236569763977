/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.DocumentationPage {
    padding-top: 25px;
    min-height: calc(100vh - 333px);
    @include container;
    &-Title {
        text-align: center;
    }
    &-NoResults {
        margin-top: 20px;
    }
    &-Tags {
        margin: 20px 0 40px;
        @include md {
            margin: 40px 0;
        }
    }
    &-Tag {
        display: inline-block;
        background: #F4F5F6;
        padding: 5px 15px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 300;
        margin: 10px 10px 0 0;
        cursor: pointer;
        &_Active {
            color: #FFF;
            background: #191C1E;
        }
    }
    &-Docs {
        ul,
        &_isLoading  {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr;
            gap: 0px 20px;
            @include sm {
                grid-template-columns: 1fr 1fr 1fr;
            }
            @include md {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
            @include lg {
                gap: 0px 60px;
            }
        }
        li {
            border-bottom: 1px solid #DEE1E2;
        }
        a {
            display: flex;
            align-items: center;
            padding: 10px 0;
            color: #000;
            word-break: break-all;
            svg {
                margin-right: 10px;
            }
            &:hover {
                color: $default-primary-base-color;
                text-decoration: none;
                svg {
                    fill: $default-primary-base-color;
                }
            }
        }
        .TextPlaceholder {
            border-bottom: 1px solid #DEE1E2;
            padding: 10px 0;
        }
    }
}