@mixin grey-page-bg {
    @include mobile {
        background: #fff;
    }

    &::before {
        z-index: -1;
        content: '';
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: var(--secondary-base-color);

        @include after-mobile {
            content: none;
        }
    }
}

// Container
@mixin container {
    max-width: $container-width;
    padding-left: $container-gutter;
    padding-right: $container-gutter;
    margin: 0 auto;
}

// Font size
@mixin text-xs {
    font-size: $fs-xs;
    line-height: 1rem;
}
@mixin text-sm {
    font-size: $fs-sm;
    line-height: 1.25rem;
}
@mixin text-base {
    font-size: $fs-base;
    line-height: 1.5rem;
}
@mixin text-lg {
    font-size: $fs-lg;
    line-height: 1.75rem;
}
@mixin text-xl {
    font-size: $fs-xl;
    line-height: 1.75rem;
}
@mixin text-2xl {
    font-size: $fs-2xl;
    line-height: 2rem;
}
@mixin text-3xl {
    font-size: $fs-3xl;
    line-height: 2.25rem;
}
@mixin text-4xl {
    font-size: $fs-4xl;
    line-height: 2.5rem;
}
@mixin text-5xl {
    font-size: $fs-5xl;
    line-height: 1;
}
@mixin text-6xl {
    font-size: $fs-6xl;
    line-height: 1;
}
@mixin text-7xl {
    font-size: $fs-7xl;
    line-height: 1;
}
@mixin text-8xl {
    font-size: $fs-8xl;
    line-height: 1;
}
@mixin text-9xl {
    font-size: $fs-9xl;
    line-height: 1;
}

// Page title
@mixin page-title {
    font-size: 26px;
    line-height: 1.75rem;
    @include lg {
        font-size: 40px;
        line-height: 1;
    }
}

// Arrow
@mixin arrow-right($size, $weight) {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        height: $size;
        width: $size;
        transform: rotate(-45deg);
        border-color: currentColor;
        border-style: solid;
        border-width: 0 $weight $weight 0;
    }
}