/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Menu {
    &-Wrapper {
        z-index: 11;
    }

    &-First,
    &-Second,
    &-Third  {
        width: 100%;
        background: #FFF;
        z-index: 1;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        overflow-y: auto;
        z-index: 12;
        padding-left: $container-gutter;
        padding-right: $container-gutter;
        &::-webkit-scrollbar-track {
            background-color: #ECECEC;
        }
        &::-webkit-scrollbar {
            width: 10px;
            background-color: #ECECEC;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #5A6870;
        }
    }

    &-First {
        width: 100%;
        left: 0;
        padding-top: 20px;
        padding-bottom: 20px;
        @include md {
            padding-top: 50px;
            padding-bottom: 50px;
            max-width: 280px;
        }
        .Menu-Link {
            padding: 10px 0;
            text-decoration: none;
            &_isActive {
                font-weight: 700;
            }
        }

        & + .Menu-Close {
            @include md {
                left: 280px;
            }
        }
    }

    &-Second  {
        box-shadow: -5px 0px 30px #0000001A;
        @include md {
            padding: 50px 60px;
            max-width: calc(100% - 280px);
            left: 280px;
            background: #F6F6F6;
        }
        .Menu-SubmenuTitle {
            @include md {
                display: none;
            }
        }
        .Menu-SubmenuList {
            @include md {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }
        .Menu-Link {
            display: block;
            background: #FFF;
            cursor: pointer;
            padding: 15px 0;
            border-bottom: 1px solid #ECECEC;
            font-size: 14px;
            @include md {
                display: flex;
                width: 100%;
                height: 95px;
                flex-direction: column;
                justify-content: center;
                border: 1px solid #ECECEC;
                padding: 0 35px;
                margin-bottom: 30px;
                font-size: 18px;
            }
            @include lg {
                width: calc(100% / 2 - 15px);
            }
            @include xl {
                width: calc(100% / 3 - 20px);
            }
            &:hover {
                text-decoration: none;
                background: #F6F6F6;
            }
        }
    }

    &-Third {
        background: #FFF;
        padding-bottom: 20px;
        @include md {
            padding: 60px;
            max-width: calc(100% - 280px);
            left: 280px;
        }
        .Menu-SubmenuList {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -13px;
        }

        .Menu-Link {
            width: calc(100% / 3);
            text-align: center;
            margin-top: 30px;
            padding: 0 13px;
            @include lg {
                width: calc(100% / 5);
            }
            // @include xl {
            //     width: calc(100% / 8);
            // }
        }
        .Menu-ItemCaption {
            margin-top: 10px;
            font-size: 14px;
            word-wrap: break-word;
            @include md {
                font-size: 18px;
            }
        }
    }

    &-SubmenuTitle {
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        color: #5A6870;
        font-size: 18px;
        padding: 20px 0 5px;
        @include md {
            padding: 0;
        }
        svg {
            fill: #5A6870;
            margin-right: 20px;
        }
    }

    &-Link {
        cursor: pointer;
        display: block;
        color: $color-text;
        font-size: 18px;
        font-weight: 300;
        line-height: 1.2;
    }

    &-Image {
        width: 90px;
        height: 90px;
        padding-bottom: 0;
        vertical-align: top;
        @include md {
            width: 110px;
            height: 110px;
        }
    }

    &-Close {
        background: #5A6870;
        display: flex;
        width: 50px;
        height: 50px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 99;
        cursor: pointer;
    }

    &-Overlay {
        background-color: rgba(25, 28, 30, .2);
        height: 100vh;
        width: 100%;
        position: absolute;
        z-index: 11;
        top: 0;
        left: 0;
        cursor: pointer;
    }
}