/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ResetAttributes {
    display: flex;
    flex-wrap: wrap;
    @include desktop {
        margin-block-end: 20px;
    }

    @include mobile {
        margin: 16px;
        margin-block-end: 0;
    }

    &-AttributeValue {
        display: flex;
        align-items: center;
        background: #F6F6F6;
        padding: 5px 10px;
        margin: 0 10px 10px 0;
    }

    &-AttributeText {
    }

    &-AttributeLabel {
    }

    &-CloseIcon {
        margin-left: 5px;
        .CloseIcon {
            height: var(--checkbox-height);
            width: var(--checkbox-width);
        }
    }
}
