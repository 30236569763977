/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --store-switcher-list-background: #fff;
    --store-switcher-title-color: #000;
}

.StoreSwitcher {
    position: relative;

    &:before {
        content: ' ';
        position: absolute;
        top: 6px;
        left: 0;
        display: block;
        width: 1px;
        height: 10px;
        background: #ECECEC;
        @include lg {
            height: 20px;
            top: 3px;
        }
    }

    select,
    select:hover,
    select:focus {
        @include before-desktop {
            border: 0;
        }
    }

    select {
        height: auto;
        border: none;
        padding: 0 15px 0 10px;
        font-size: 14px;
        @include lg {
            font-size: 18px;
            padding: 0 15px 0 30px;
        }
        .Header_isHomepage & {
            color: #FFF;
        }
    }

    .Field {
        margin-top: 0;
    }

    .FieldSelect {
        &-Options {
            border-top-width: 1px;
        }
        &-Option {
            padding: 5px 10px;
            text-align: center;
            max-height: initial;
            line-height: initial;
        }
    }

    .ChevronIcon {
        width: 12px;
        height: 12px;
        top: 5px;
        right: 0;
        @include lg {
            top: 8px;
        }
        .Header_isHomepage & {
            fill: #FFF;
        }
    }
}
