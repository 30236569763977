/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

:root {
    --blog-post-card-title-weight: 700;
    --blog-post-card-title-size: 16px;
    --blog-post-card-description-height: 100px;
    --blog-post-card-border: 1px solid lightgray;
}

.BlogPostCard {
    width: 100%;
    margin-top: 40px;
    @include md {
        width: calc(50% - 10px);
    }
    @include lg {
        width: calc(50% - 30px);
    }

    &_isLoading {
        padding: 37% 0 0;
        animation: var(--placeholder-animation);
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        box-shadow: var(--attraction-shadow);
    }

    &-Link {
        display: block;
        color: $color-text;
        &:hover {
            text-decoration: none;
            color: $color-accent;
        }
    }

    &-ImageWrapper {
        display: block;
    }

    &-Image {
        padding-bottom: 67%;
        display: block;
    }

    &-Details {
        margin-top: 15px;
    }

    &-Title {
        display: block;
        font-size: 18px;
        font-weight: 700;
    }

    &-Description {
        font-size: 14px;
        font-weight: 300;
        margin-top: 5px;
    }

    &-Date {
        display: none;
    }

}
