/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CarouselScrollItem {
    position: relative;
    display: flex;
    height: var(--carousel-item-height);
    width: 100%;
    scroll-snap-align: start;
    transition: border-color var(--animation-speed), filter var(--animation-speed);
    flex: 1;
    cursor: pointer;
    @include md {
        padding-left: 20px;
        margin: 5px 0;
    }
    @include xl {
        padding-left: 50px;
        margin: 10px 0;
    }
    &:before {
        content: ' ';
        display: block;
        width: 5px;
        height: 100%;
        background: #ECECEC;
        position: absolute;
        top: 0;
        left: 0;
        @include xl {
            width: 12px;
        }
    }

    &_isActive {
        &:before {
            width: 10px;
            background: #5A6870;
            @include xl {
                width: 25px;
            }
        }
    }

    &:focus,
    &:hover {

    }
}
