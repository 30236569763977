$white: #fff;
$black: #0a0a0a;
$default-primary-base-color: #FFC20E;
$default-primary-dark-color: #d1490c;
$default-primary-light-color: #f69e77;
$default-secondary-base-color: #eee;
$default-secondary-dark-color: #929292;
$default-secondary-light-color: #f8f8f8;
$font-muli: 'Muli', sans-serif;
$font-standard-size: 62.5%;

// Color
$color-accent: #FFC20E;
$color-text: #000;
$color-border: #5A6870;

// Line heights
$lh-none: 1;
$lh-tight: 1.25;
$lh-snug: 1.375;
$lh-normal: 1.5;
$lh-relaxed: 1.625;
$lh-loose: 2;

// Font size
$fs-xs: 0.75rem;
$fs-sm: 0.875rem;
$fs-base: 1rem;
$fs-lg: 1.125rem;
$fs-xl: 1.25rem;
$fs-2xl: 1.5rem;
$fs-3xl: 1.875rem;
$fs-4xl: 2.25rem;
$fs-5xl: 3rem;
$fs-6xl: 3.75rem;
$fs-7xl: 4.5rem;
$fs-8xl: 6rem;
$fs-9xl: 8rem;

// Layout
$container-width: 1765px;
$container-gutter: 1.25rem;