fieldset {
    border: 0;
    margin-top: 40px;
}

legend {
    display: block;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 20px;
}
