/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.NoMatch {
    opacity: 0;
    animation: appear 100ms 300ms forwards;

    &-Wrapper {
        display: flex;
        justify-content: center;
        padding-top: 130px;
        min-height: calc(100vh - 293px);
    }

    &-Icon {
        margin-right: 60px;
    }

    h1 {
        font-size: 100px;
        font-weight: 700;
        line-height: 1;
    }

    h2 {
        margin-top: 20px;
    }

    p {
        max-width: 415px;
        line-height: $lh-loose;
        margin-top: 35px;
    }

    a {
        color: $color-text;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}
