:root {
    --input-border-color: #e0e0e0;
    --input-border-color-focus: #{$black};
    --input-background-color: #fff;
    --input-padding: 10px 0;
    --input-color: var(--primary-base-color);
}

label {
    @include text-sm;
    display: inline-block;
    font-weight: 700;
    margin-bottom: 10px;
    cursor: pointer;
}

input,
textarea,
select {
    border: none;
    border: 1px solid $color-border;
    background-color: transparent;
    padding: 0 20px;
    appearance: none;
    color: #000000;
    font-size: 16px;
    height: 45px;
    outline: 0;
    width: 100%;
    font-weight: 300;

    &:focus {
        border-color: $color-accent;
    }

    &::placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
        color: #000;
    }
    &::placeholder {
        opacity: 1;
    }
}

textarea {
    width: 100%;
    min-height: 80px;
    padding-top: 10px;
    padding-bottom: 10px;
    vertical-align: top;
}

[type='number'] {
    width: var(--input-type-number-width);
    font-weight: 700;
    padding-right: 0;
    -moz-appearance: textfield;

    &:focus {
        border: 1px solid var(--input-border-color);
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
    }

    ~ button {
        position: absolute;
        right: 0;
        padding: 0;
        margin: 0;
        width: 1.8rem;
        font-size: 1.8rem;
        line-height: 0;

        @include mobile {
            width: 2.1rem;
            font-size: 2.1rem;
        }

        &:first-of-type {
            // @include plus-icon;
        }

        &:last-of-type {
            // @include minus-icon;
            bottom: 0;
        }

        &:first-of-type,
        &:last-of-type {
            height: 50%;
        }

        &:disabled {
            opacity: .2;
            cursor: not-allowed;
        }
    }
}

