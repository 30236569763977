/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --expandable-content-color: #000;
    --expandable-content-divider-color: var(--primary-divider-color);
}

@mixin expanded-content {
    display: block;
}

.ExpandableContent {

    &-Button {
        text-align: left;
        width: 100%;
        will-change: border-color;
        min-height: var(--header-nav-height);
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-right: 15px;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            height: 6px;
            width: 6px;
            transform: rotate(-45deg);
            border-color: currentColor;
            border-style: solid;
            border-width: 0 0 1px 1px;
        }

        &_isContentExpanded {
            &::after {
                border-width: 1px 0 0 1px;
                transform: rotate(45deg);
            }
        }

        svg {
            display: none;
        }
    }

    &-Heading,
    &-SubHeading {
        display: block;
        color: var(--expandable-content-color);
    }

    &-Heading {
        word-break: break-all;
    }

    &-SubHeading {
        font-weight: 300;
        font-size: .96rem;
        line-height: 1.1;
        font-size: 1.12rem;
    }

    &-Content {
        display: none;
        &_isContentExpanded {
            @include expanded-content;
        }
    }
}
