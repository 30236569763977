/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Pagination {
    display: flex;
    justify-content: center;
    margin-inline: auto;
    margin-block-start: 40px;
    align-items: center;
    z-index: 2;
    width: min-content;

    @include desktop {
        order: 1;
    }

    @include mobile {
        padding-block-end: 0;
        display: none;
    }

    .ChevronIcon {
        cursor: pointer;
        display: block;
        height: 20px;
        width: 16px;

        &:hover,
        &:focus {
            fill: var(--primary-base-color);
        }
    }

    &-ListItem {
        margin: 0 5px;
        padding-inline-start: 0;
        min-width: 35px;
        display: block;

        &::before {
            content: '';
        }

        &:first-child {
            @include mobile {
                padding-inline-start: 0;
            }
        }

        &:last-child {
            margin-block-end: 0;
        }
    }
}
