/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --newsletter-subscription-input-background: var(--input-background-color);
    --newsletter-subscription-input-color: #{$black};
    --newsletter-subscription-placeholder-color: var(--secondary-dark-color);
    --newsletter-subscription-button-background: var(--link-color);
}

.NewsletterSubscription {
    margin: 40px auto 0;
    padding: 0 $container-gutter;
    width: 100%;
    max-width: 600px;
    @include sm {
        margin: 80px auto 0;
    }

    .NewsletterSubscription {
        padding: 0;
        margin: 0;
    }

    &-Title {
        font-size: 16px;
        font-weight: 700;
        display: block;
        margin-bottom: 25px;
        text-align: center;
        @include sm {
            font-size: 22px;
        }
    }

    &-Gdpr {
        display: block;
        color: #5A6870;
        margin-top: 20px;
        a {
            color: #5A6870;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }

    .FieldForm {
        &-Body {
            @include sm {
                display: flex;
                flex-wrap: nowrap;
                align-items: flex-start;
            }
        }
    }

    .FieldForm {
        &-Fieldset {


            &_isLoading {
                opacity: .7;
                pointer-events: none;
            }

            .Field {
                width: 100%;
                margin-top: 0;
            }
        }

        &-Fields {
            margin: 0 0 10px 0;
            @include sm {
                margin: 0 10px 0 0;
                flex: 1;
            }
        }

        &-Button {
            width: 100%;
            height: 45px;
            padding: 0 15px;
            text-transform: none;
            @include sm {
                max-width: 190px;
            }
        }
    }

    .Form {
        &_isInvalid {
            margin-bottom: 1.8rem;
        }
    }
}
