/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CmsPage {
    &-Wrapper {
        @include container;
        max-width: 840px;
        &_page_width_full {
            max-width: 1180px;
        }
    }

    &-Heading {
        margin: 25px 0 30px;
        text-align: center;
        @include md {
            margin: 25px 0 75px;
        }
    }

    &-Content {
        line-height: $lh-loose;

        ul,
        ol {
            font-size: 14px;
            font-weight: 300;
            margin-bottom: 15px;
            @include md {
                margin-bottom: 30px;
            }
        }

        li {
            position: relative;
            padding-left: 25px;
            &:before {
                content: ' ';
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background: #191C1E;
                position: absolute;
                top: 10px;
                left: 0;

            }
        }

        p {
            font-size: 14px;
            font-weight: 300;
            margin-bottom: 15px;
            @include md {
                margin-bottom: 30px;
            }
        }

        h2 {
            &:first-of-type {
                margin-top: 0;
            }
        }

        a {
            color: $color-text;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }

    .Image {
        padding: 0;
        height: auto;
        img {
            position: static;
        }
    }

    .TextPlaceholder {
        margin-bottom: 2.16rem;
        display: block;

        @include mobile {
            margin-bottom: 2.52rem;
        }
    }

    h2 {
        font-size: 18px;
        margin: 30px 0 15px;
        @include md {
            font-size: 22px;
            margin: 60px 0 30px;
        }
    }

    .Button-Primary {
        color: #FFF;
        text-decoration: none;
    }
}

.cms-catalogs {
    & + .cms-catalogs {
        margin-top: 100px;
    }

    &-title {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 700;
        padding-bottom: 10px;
        border-bottom: 1px solid #5A6870;
        margin-bottom: 60px;
    }
}

.cms-catalog {
    @include md {
        display: flex;
    }

    & + .cms-catalog {
        margin-top: 60px;
    }

    &:nth-child(odd) {
        .cms-catalog-image {
            @include md {
                padding-left: 30px;
                padding-right: 0;
            }
        }
        .cms-catalog-content {
            @include md {
                order: -1;
                padding-right: 30px;
                padding-left: 0;
            }
        }
    }

    &-image,
    &-content {
        @include md {
            width: 50%;
        }
    }
    &-image {
        @include md {
            padding-right: 30px;
        }
    }
    &-content {
        margin-top: 20px;
        @include md {
            padding-left: 30px;
            margin-top: 0;
        }
    }

    .Image {
        vertical-align: top;
    }
}