/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ContactPage {
    &-Heading {
        @include page-title;
        padding: 30px $container-gutter;
        border-bottom: 1px solid #707070;
        margin: 0 -1 * $container-gutter 40px;
        @include lg {
            border-bottom: 0;
            text-align: center;
            padding: 0;
            margin: 25px 0 75px;
        }
    }

    &-ColumnWrapper {
        @include lg {
            display: flex;
        }
    }

    &-Column {
        @include lg {
            flex: 1;
            &:first-child {
                padding-right: 30px;
            }
            &:last-of-type {
                padding-left: 30px;
            }
        }
    }

    &-Company,
    &-Network {
        &-ExpandableContentButton {
            @include text-2xl;
            font-weight: 700;
            text-transform: uppercase;
            border-top: 1px solid #5A6870;
            border-bottom: 1px solid #5A6870;
            padding: 40px $container-gutter;
            margin: 0 -1 * $container-gutter;
            width: 100vw;
            &:after {
                top: 44px;
                right: $container-gutter;
                height: 15px;
                width: 15px;
                border-width: 0 0 3px 3px;
                border-color: #5A6870;
            }
            &_isContentExpanded:after {
                top: 52px;
                border-width: 3px 0 0 3px;
            }
            @include lg {
                width: 100%;
                pointer-events: none;
                text-transform: none;
                padding: 0;
                margin: 0;
                border: 0;
                &:after {
                    content: none;
                }
            }
        }
        &-ExpandableContentContent {
            padding-bottom: 40px;
            @include lg {
                display: block;
                padding-bottom: 0;
            }
        }
    }

    &-Company {
        margin-top: 50px;
        @include lg {
            margin-top: 0;
            background: #F4F5F6;
            padding: 45px 50px;
        }
        @include xl {
            padding: 85px 90px;
        }
        &-ExpandableContentButton {
            border-bottom: 0;
        }
        ul {
            font-size: 18px;
            font-weight: 300;
            border-bottom: 1px solid #5A6870;
            @include lg {
                margin-top: 35px;
            }
        }
        li {
            border-top: 1px solid #5A6870;
            padding: 15px 0;
            a {
                color: #000;
            }
        }
        .Button-Secondary {
            margin-top: 40px;
        }
    }

    &-Network {
        @include lg {
            margin-top: 100px;
        }
    }

    &-Country {
        margin-top: 30px;
        &-ExpandableContentButton {
            text-transform: uppercase;
            padding: 15px 0;
            font-weight: 700;
            border-bottom: 1px solid $color-border;
            &:after {
                top: 21px;
                right: 2px;
                width: 7px;
                height: 7px;
            }
            &_isContentExpanded:after {
                top: 25px;
            }
        }
        .CmsBlock-Wrapper {
            display: grid;
            grid-row-gap: 0px;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: $container-gutter;
            @include sm {
                grid-template-columns: repeat(3, 1fr);
            }
            @include md {
                grid-template-columns: repeat(4, 1fr);
            }
            @include lg {
                grid-template-columns: repeat(5, 1fr);
                grid-column-gap: 40px;
            }
            @include xl {
                grid-template-columns: repeat(6, 1fr);
                grid-column-gap: 60px;
            }
        }
    }

    &-Region {
        margin-top: 30px;
        strong {
            display: block;
            text-transform: uppercase;
            color: #5A6870;
            margin-bottom: 20px;
        }
        li {
            border-top: 1px solid #ECECEC;
            padding: 15px 0;
        }
    }
}
