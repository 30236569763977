:root {
    --button-padding: 15px 60px;
    --button-font-size: 16px;
}

button {
    outline: 0;
}

.Button:not(.Button_likeLink) {
    @extend .Button-Primary;
}

.Button_likeLink {
    text-decoration: underline;
    text-align: left;
}

.Button-Primary,
.Button-Secondary {
    display: inline-block;
    font-size: var(--button-font-size);
    text-transform: uppercase;
    padding: var(--button-padding);
    border: 1px solid transparent;
}

.Button-Primary {
    background: #191C1E;
    border-color: #191C1E;
    color: #FFF;
    text-align: center;
    &_Full {
        width: 100%;
    }
}

.Button-Secondary {
    border-color: #5A6870;
    background: #FFF;
    color: #000;
    text-align: center;
}

button {
    background-color: transparent;
}
