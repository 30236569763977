/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CarouselScroll {
    --translateY: 0;
    --animation-speed: 300ms;

    height: 100%;
    display: flex;
    align-items: center;
    margin-block-start: 28px;
    width: fit-content;

    &-ContentWrapper {
        overflow: hidden;
        flex: 1;
        display: flex;
    }

    &-Content {
        transform: translateX(var(--translateX));
        transition: transform var(--animation-speed);
        padding-inline-end: 1px;
        display: flex;
        flex-direction: column;
    }
}
