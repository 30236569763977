/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Header {
    position: sticky;
    background: #FFF;
    top: 0;
    z-index: 11;
    padding: 20px 0;
    @include lg {
        padding: 30px 0;
    }

    &_isHomepage {
        background: none;
        position: fixed;
        left: 0;
        width: 100%;
    }

    &-Container {
        @include container;
        display: flex;
        align-items: center;
    }

    &-Menu {
        margin-right: 10px;
        cursor: pointer;
        @include md {
            margin-right: 15px;
        }
        @include lg {
            margin-right: 50px;
        }
        svg {
            width: auto;
            height: 20px;
            @include lg {
                height: auto;
            }
        }
    }

    &-Logo {
        display: block;
        margin-right: auto;
        svg {
            width: auto;
            height: 22px;
            @include lg {
                height: auto;
            }
        }
    }

    &-B2B {
        position: relative;
        font-size: 14px;
        color: $color-text;
        padding: 0 10px;
        @include lg {
            padding: 0 30px;
            font-size: 18px;
        }
        .Header_isHomepage & {
            color: #FFF;
        }
        &:hover {
            color: $color-accent;
            text-decoration: none;
        }
        &:focus {
            text-decoration: none;
        }
        &:before {
            content: ' ';
            position: absolute;
            top: 6px;
            left: 0;
            display: block;
            width: 1px;
            height: 10px;
            background: #ECECEC;
            @include lg {
                height: 20px;
                top: 3px;
            }
        }
    }
}