/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Hero {
    height: 100vh;
    &-Item {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        text-align: center;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        height: 100vh;
        &:before {
            content: ' ';
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, .25);
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    &-Content {
        z-index: 1;
        color: #FFF;
    }
    &-Title {
        font-size: 32px;
        line-height: 1;
        @include lg {
            font-size: 100px;
        }
    }
    &-Subtitle {
        font-size: 20px;
        line-height: 1;
        text-transform: uppercase;
        margin-top: 20px;
        @include lg {
            font-size: 40px;
        }
    }
    &-Button {
        display: inline-block;
        font-size: 20px;
        text-transform: uppercase;
        color: #FFF;
        border: 1px solid #ECECEC;
        width: 100%;
        max-width: 170px;
        padding: 8px 0;
        margin-top: 30px;
        &:hover {
            color: #000;
            background: #ECECEC;
            text-decoration: none;
        }
        @include lg {
            padding: 20px 0;
            margin-top: 50px;
        }
    }
    &-Pagination {
        display: none;
        position: absolute;
        top: 50%;
        right: 25px;
        transform: translateY(-50%);
        flex-direction: column;
        align-items: flex-end;
        @include lg {
            display: flex;
        }
    }
    &-PaginationButton {
        display: block;
        margin: 7px 0;
        width: 6px;
        height: 60px;
        background: #FFF;
        &_isActive {
            width: 12px;
        }
    }
}