/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.PostsListing {
  @include container;
  padding-top: 25px;

  &-Title {
      text-align: center;
  }

  &-Posts {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
  }

  &-NoResults {
      margin-top: 40px;
  }

  &-LoadMoreWrapper {
      text-align: center;
      margin-top: 40px;
  }
}
