/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.FieldForm {
    input {
        width: 100%;

        &[type='checkbox'],
        &[type='radio'] {
            width: 0;
        }

        + label {
            min-width: auto;
        }
    }

    &-Fields {
    }

    &-Notes {
        font-size: $fs-sm;
        line-height: $lh-loose;
        margin-top: 20px;
        a {
            color: $color-text;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
    &-Actions {
        margin-top: 35px;
    }
}
