/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ScrollTop {
    position: fixed;
    bottom: 15%;
    right: 0;
    background: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 62px;
    height: 62px;
    color: #FFF;
    z-index: 10;
    cursor: pointer;
    span {
        text-transform: uppercase;
        font-size: 12px;
    }
}