h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
}

h1 {
    font-size: 26px;
    @include md {
        font-size: 40px;
    }
}

h2 {
    @include text-3xl;
}

h3 {
    font-size: 22px;
}

h4 {
    @include text-xl;
}

h5 {
    @include text-lg;
}

h6 {
    @include text-base;
}